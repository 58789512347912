import React from "react";

const NotFound404 = () => {
  return (
    <div className="container text-center">
      <h1>Page Not Found</h1>
      <img src="../peachcat-sleep.gif" alt="" />
    </div>
  );
};

export default NotFound404;
